<template>
  <app-table
    tablenom="sublistevaluations"
    :cols="colonnes"
    :items="evaluations"
    :loadstatus="evaluationsLoadStatus"
    :deletestatus="evaluationDeleteStatus"
    :formroutes="evaformroutes"
    :actions="evaActions"
  >
  </app-table>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      //
    };
  },
  props: {
    evaluations: Array,
    evaformroutes: Object,
  },
  computed: {
    evaluationsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.eva.evaluationsLoadStatus;
    },
    evaluationDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.eva.evaluationDeleteStatus;
    },
    colonnes() {
      return this.$store.state.eva.colonnes;
    },
    evaActions() {
      return this.$store.state.eva.actions;
    },
  },
};
</script>
