<template>
  <div>
    <el-row style="width: 1380px" v-loading="tutoratLoadStatus == 1">
      <el-col :span="6">
        <form-tutorat
          :ismedtut="ismedtut"
          :ismedpup="ismedpup"
          :evaldates="evalMinMaxDates"
          :inittrim="initTrim"
          ref="formTut"
        >
        </form-tutorat>
      </el-col>
      <el-col :span="18">
        <h2>Evaluations</h2>
        <sublist-evaluation
          :evaluations="evaluations"
          :evaformroutes="routes.evaform"
        >
        </sublist-evaluation>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormTutorat from "@/components/rh/ui/form/FormTutorat";
import SublistEvaluation from "@/components/rh/listes/SublistEvaluation";
import { trimStringInObj } from "@/fonctions";

export default {
  components: {
    "form-tutorat": FormTutorat,
    "sublist-evaluation": SublistEvaluation,
  },

  data() {
    return {
      initTrim: "", // récupère la valeur initiale de prochain_eval_date pour la rajouter aux options du form si elle n'y est pas
    };
  },
  props: {
    ismedtut: Boolean,
    ismedpup: Boolean,
    routes: Object,
  },
  created() {
    this.$store.dispatch("tut/loadTuteur", this.idTutorat);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idTutorat() {
      // récupère le id du tutorat dans l'url
      return String(this.$route.params.idtutorat);
    },
    tutoratLoadStatus() {
      return this.$store.state.tut.tuteurLoadStatus;
    },
    tutoratUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.tut.tuteurUpdateStatus;
    },
    evaluations() {
      const evals = this.$store.state.eva.evaluations;
      return evals.filter((e) => e.id_medtuteur == this.idTutorat);
    },
    evalMinMaxDates() {
      if (this.evaluations.length === 0) {
        return {};
      } else {
        const ed = this.evaluations.map((e) => e.eval_date).sort();
        return { min: ed[0], max: ed[ed.length - 1] };
      }
    },
  },
  methods: {
    afficheData() {
      if (this.tutoratLoadStatus == 2) {
        // charge les données du tutorat
        const tut = this.$store.state.tut.tuteur;
        this.initTrim = tut.prochain_eval_date;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.tutLoaded = JSON.stringify(tut);
      } else if (this.tutoratLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formTut.$refs.formTutorat.validate((valid) => {
        if (valid) {
          let tut = this.$store.state.tut.tuteur;
          // enlever les espaces avant et après dans les string
          trimStringInObj(tut);

          const tutsInBD = this.$store.getters["tut/TutsInBD"];
          const id = this.idTutorat;
          const doublon =
            tutsInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == tut.id_pupille &&
                m[2] == tut.id_tuteur &&
                m[3] == tut.debut_date
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modification et pour l'enregistrement
          const tutStr = JSON.stringify(tut);

          // vérifie si les données ont été modifiées
          if (localStorage.tutLoaded == tutStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si un tutorat entre les mêmes méd et avec la même date de debut n'est pas déjà enregistré
          } else if (doublon) {
            this.$alert(
              "Un tutorat entre ces 2 personnes avec la même date de début est déjà enregistré !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("tut/updateTuteur", tut);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const tut = this.$store.state.tut.tuteur;
      const tutStr = JSON.stringify(tut);
      if (localStorage.tutLoaded != tutStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.tutLoaded);
        this.$store.commit("tut/setTuteur", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modification
      const tut = this.$store.state.tut.tuteur;
      const tutStr = JSON.stringify(tut);

      if (localStorage.tutLoaded == tutStr) {
        // si pas de modifications
        // retourne à la liste des tutorats
        this.$router.push(this.routes.tolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des tutorats
          this.$router.push(this.routes.tolist);
        });
      }
    },
  },
  watch: {
    tutoratLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    tutoratUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.tutoratUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        const tut = this.$store.state.tut.tuteur;
        localStorage.tutLoaded = JSON.stringify(tut);
      } else if (this.tutoratUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
